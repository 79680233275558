//- smooth jquery anchors (Jquery dependant)
//-------------------------------------------

function anchors() {
	$('.is-anchor').on('click', function(event) {     
		event.preventDefault();
		var target = $(this).attr('href');

		$('html,body').animate({
			scrollTop: $(target).offset().top
		}, 600);

		if (helpers.isMobile) {
			window.setTimeout(function() {
				$('.hamburger').toggleClass('is-active');
				$('.header-navigation').toggleClass('is-open');
				$('.logo').fadeToggle();
			}, 500); 
		}
	});
}

//- generic modals (Jquery dependant)
//-------------------------------------------

function openModal(button, modal) {

	helpers.lockBody();

	button = $(button); 
	modal = $(modal);


	button.on('click', function(e){
		var instance = $(this).data('instance'); 
		e.preventDefault();

		// overlay is mostly a generic 
		$('.overlay').fadeIn(400, function() {
			modal.fadeIn();
			$(instance).fadeIn();
		});
	});

}

function closeModal(button, modal) {

	button = $(button); 
	modal = $(modal);

	button.on('click', function() { 

		$(modal).fadeOut(400, function() {
			$('.overlay').fadeOut();
		});

	});

	helpers.unlockBody();
}

function headerChanges() {
	if (window.pageYOffset > 100) {
		$('.transparent').addClass('scrolled');
		$('.fixed-logo').fadeIn(500);
	}
	
	$(window).on('scroll', function(){
		if (window.pageYOffset > 100) {
			$('.transparent').addClass('scrolled');
			$('.fixed-logo').fadeIn(500);
		}

		else {
			$('.fixed-logo').hide();
			$('.transparent').removeClass('scrolled');
		}
	});
}

function openQuestion() {
	$('.open-question').on('click', function() {
		// instantiate elements 
		var parent = $(this).parent().parent(); 
		var sibling = $(parent).children('.accordion-answer');

		// reset everything on click
		$('.accordion-answer').slideUp(); 

		// logical test for click on open element
		if ( parent.hasClass('is-open') ) {
			parent.removeClass('is-open');
			sibling.slideUp();
		}

		else {
			$('.accordion-instance').removeClass('is-open');
			parent.addClass('is-open'); 
			sibling.slideDown();
		}
	});
}

function openMenu() {
	$('.hamburger').on('click', function() {
		$(this).toggleClass('is-active');
		$('.header-navigation').toggleClass('is-open');
		$('.logo').fadeToggle();
	});
}

function openSubMenu() {
	$('.has-submenu').on('click', function() {
		$(this).children('.mega-menu').addClass('is-open');
		$('.transparent').addClass('submenu-open');
	});
}

function closeSubMenu() {
	$('.close-mega-menu').on('click', function() {
		$('.mega-menu').removeClass('is-open');
		$('.transparent').removeClass('submenu-open');
	});
}


// informative modal and cookie 


function setCookie(name, value, days) {
	var expires = "";
	if (days) {
		var date = new Date();
		date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
		expires = "; expires=" + date.toUTCString();
	}
	document.cookie = name + "=" + (value || "") + expires + "; path=/";
}


function getCookie(name) {
	var dc = document.cookie;
	var prefix = name + '=';
	var begin = dc.indexOf('; ' + prefix);
	if (begin == -1) {
		begin = dc.indexOf(prefix);
		if (begin !== 0) return null;
	}
	else {
		begin += 2;
		var end = document.cookie.indexOf(';', begin);
		if (end == -1) {
			end = dc.length;
		}
	}

	return decodeURI(dc.substring(begin + prefix.length, end));
}


// function doCookieStuff() {
// 	var cookie = getCookie('modal');

// 	if (cookie === null) {
// 		setCookie('modal', 'visitou no último dia', 1);

// 		$('.overlay').fadeIn(400, function(){
// 			$('.informative').fadeIn();
// 		});

// 	}
// }

// function closeInformative() {
// 	$('.informative').fadeOut(400, function(){
// 		$('.overlay').fadeOut();
// 	});
// }

window.onload = function() {
	$('body').addClass('loaded');
	openModal('.we-call, .open-modal', '.modal-background'); 
	closeModal('.close-modal', '.modal-background');
	openQuestion();
	anchors();
	// $('.close-informative').on('click', closeInformative);
	// doCookieStuff();

	if (helpers.isMobile) {
		helpers.carousel('.becomes-carousel-on-mobile', helpers.carouselOptions.mobileBasic)
		openMenu();
		openSubMenu();
		closeSubMenu();
	}

	else {
		headerChanges();
	}
}
//- generic helpers
//-------------------------------------------

var helpers = {
	lockBody : function()  {
		document.body.classList.add('no-scroll');
	},
	unlockBody : function() {
		document.body.classList.remove('no-scroll');
	},

	isMobile : window.innerWidth < 1000 ? true : false, 

	carousel : function(selector, options) {
		$(selector).slick(options);
	}, 

	carouselOptions : {
		mobileBasic : {
			slidesToShow : 1, 
			centerMode : true, 
			centerPadding : 0,
			arrows : false, 
			dots : false,
			mobileFirst: true, 
			responsive : [
			{
				breakpoint: 600, 
				settings : {
					slidesToShow : 2,
				}
			}, 
			]
		}
	}
}